/* Packages.css */

/* Apply the background to the whole page */
body {
  background: linear-gradient(180deg, #4c6793); /* Example gradient background */
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif; /* Consistent font styling */
}

.page-container {
  background-color: transparent; /* Ensure no background color is applied to the container */
}
