/* PackagesSection.css */
.packages-section {
    padding: 60px 20px;
    color: #fff;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: #102A43;
    letter-spacing: 1px;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #64748B;
  }
  
  .package-cards {
    display: flex;
    justify-content: center; /* Center the package cards */
    flex-wrap: wrap;
    gap: 20px; /* Space between the cards */
  }
  