@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.faq-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  font-family: 'Poppins', sans-serif;
}

.faq-heading {
  font-size: 2.4em;
  font-weight: 600;
  text-align: center;
  color: #444;
  margin-bottom: 35px;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.faq-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  padding: 15px 20px;
  font-size: 1.1em;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.faq-item.active {
  background-color: #eaf5ff;
  transform: scale(1.02);
}

.faq-item:hover {
  background-color: #f0f8ff;
  transform: scale(1.01);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
}

.faq-icon {
  font-size: 1.4em;
  color: #0066cc;
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
  background-color: #fff;
  padding: 0 20px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  font-size: 1em;
  color: #666;
  line-height: 1.6;
  margin-top: 10px;
}

.faq-item.active .faq-answer {
  max-height: 500px; /* Adjust to fit the content */
  opacity: 1;
  padding: 15px 20px;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.faq-answer p {
  margin: 0;
  color: #777;
}
