.get-started-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    font-size: 2rem;
    color: #ff6600;
    margin-bottom: 10px;
  }
  
  .subheading {
    text-align: center;
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  
  .step {
    flex: 1;
    text-align: center;
    font-size: 0.9rem;
    color: #666;
    border-bottom: 3px solid #e0e0e0;
    padding-bottom: 10px;
  }
  
  .step.active {
    color: #003366;
    border-bottom: 3px solid #ff6600;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    min-width: 200px;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .form-group:last-child {
    margin-right: 0;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .btn {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #ff6600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #e65c00;
  }
  