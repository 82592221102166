/* src/components/packagesmodules/RefundPolicyPopup.css */

.refund-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; /* Increased responsiveness */
    max-width: 600px; /* Reduced max-width for better manageability */
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    z-index: 1002; /* Ensure it's above other popups */
    animation: fadeIn 0.3s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .popup-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #aaa;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  
  .close-button:hover {
    color: #FF7F3F; /* Match the previous hover color */
  }
  
  .popup-contentrefund {
    padding: 20px;
    max-height: 60vh; /* Adjusted for better viewing */
    overflow-y: auto;
    text-align: left;
    color: #333;
  }
  
  .popup-content h3 {
    margin-top: 1.5rem;
    color: #333;
  }
  
  .popup-content p,
  .popup-content ul,
  .popup-content li {
    line-height: 1.6;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .refund-popup {
      width: 95%;
      max-width: none;
      height: 80vh;
    }
  
    .popup-content {
      padding: 15px;
    }
  
    .popup-header h2 {
      font-size: 1.3rem;
    }
  
    .close-button {
      font-size: 1.3rem;
    }
  }
  