.main-content {
    padding-top: 142px; /* Keep the existing padding */
    background: none; /* Keep the existing background setting */
    text-align: left; /* Align text to the left */
    max-width: 1000px; /* Increase the width to accommodate more boxes in a row */
    margin: 0 auto; /* Center the entire main content */
}

/* Adjusted for the main heading */
.main-heading {
    text-align: left;
    font-size: 3rem;
    margin-bottom: 40px;
    margin-left: -100px; /* Apply a small negative margin to move it slightly left */
}

.btn-orange {
    background-color: #87CEEB; /* Set the button background color to sky blue */
    color: white; /* Set text color to white */
    border: none; /* Remove border */
    padding: 10px 20px; /* Button padding */
    font-size: 16px; /* Font size */
    border-radius: 5px; /* Button border-radius */
    text-align: left; /* Align the button text to the left */
    cursor: pointer; /* Show pointer on hover */
    display: inline-block; /* Ensure it behaves as an inline element */
    margin-left: -100px; /* Same negative margin as the main heading */
}

.btn-orange:hover {
    background-color: #00BFFF; /* Darker shade of sky blue for hover effect */
}

@media (max-width: 1200px) {
    .main-heading {
        font-size: 2.5rem; /* Adjust font size for medium screens */
        margin-left: -30px; /* Adjust negative margin for medium screens */
    }

    .btn-orange {
        margin-left: -30px; /* Adjust button alignment for medium screens */
    }
}

@media (max-width: 768px) {
    .main-heading {
        font-size: 2rem; /* Smaller font size for smaller screens */
        text-align: center; /* Center the heading on smaller screens */
        margin-left: 0; /* Remove left margin for smaller screens */
    }

    .btn-orange {
        width: 100%; /* Full-width button on small screens */
        text-align: center; /* Center-align the button text */
        margin-left: 0; /* Remove left margin on small screens */
    }
}

/* Benefits Heading */
.benefits-heading {
    margin-top: 250px;
    font-size: 2rem;
    color: #343a40; /* Matches the navbar text color */
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    width: 100%; /* Ensure the heading spans the full width of its container */
}

/* Benefits Grid Styling */
.benefits-grid {
    display: flex;
    flex-wrap: wrap; /* Allows the boxes to wrap onto the next line */
    gap: 20px; /* Space between the benefit boxes */
    justify-content: center; /* Center the grid within the main content */
}

.benefit-box {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 300px; /* Set the width for each box */
    height: 200px; /* Set the height for each box */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.benefit-box:hover {
    transform: translateY(-5px); /* Moves the box up on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow on hover */
}

.icon-placeholder img {
    width: 50px; /* Set the width of the icon */
    height: 50px; /* Set the height of the icon */
    margin-bottom: 10px; /* Add some space below the icon */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.benefit-box strong {
    font-size: 1.1rem; /* Slightly increase the font size for the heading */
    margin-bottom: 5px;
    text-align: center; /* Center-align the heading text */
}

.benefit-box p {
    font-size: 0.9rem; /* Slightly larger font size for the text */
    margin: 0;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit text to 3 lines */
    -webkit-box-orient: vertical;
    text-align: center; /* Center-align the paragraph text */
}

/* Commitment Section */
.commitment-section {
    text-align: center;
    margin-top: 150px;
}

.commitment-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Space between icon and text */
}

.commitment-icon {
    width: 150px; /* Adjust size as needed */
    height: auto;
    padding-bottom: 100px;
}

.commitment-text {
    max-width: 500px;
    text-align: left;
}

.commitment-section h2 {
    font-size: 2.5rem;
    color: #343a40;
    font-weight: bold;
    margin-bottom: 20px;
}

.commitment-section p {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.commitment-section ul {
    list-style-type: none;
    padding: 0;
    columns: 2; /* Split the list into 2 columns */
}

.commitment-section ul li {
    padding-left: 5px;
    font-size: 1rem;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.commitment-section ul li::before {
    content: '✔'; /* Checkmark before each list item */
    margin-right: 10px;
    color: #50C878; /* Same color as the icons */
}

/* Large button styling */
.btn-large-orange {
    background-color: #10c52e;
    color: white;
    border: none;
    width: 500px;
    padding: 15px 30px; /* Larger padding for a bigger button */
    font-size: 18px; /* Larger font size */
    border-radius: 5px;
    margin-top: 30px;
    margin-right: -120px;
    cursor: pointer;
}

.btn-large-orange:hover {
    background-color: #00BFFF;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .benefits-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
    }
}

@media (max-width: 768px) {
    .main-content {
        padding-top: 100px;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
    }

    .benefits-grid {
        grid-template-columns: 1fr; /* Stack columns on smaller screens */
    }
}
