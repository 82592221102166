/* PackageCard.css */
.package-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: left;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    color: #102A43;
  }
  
  .package-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #334E68;
  }
  
  .package-price {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #1C3D5A;
  }
  
  .package-features {
    list-style: none;
    padding: 0;
    color: #334E68;
  }
  
  .package-features li {
    margin-bottom: 10px;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .package-features li::before {
    content: '✔️';
    margin-right: 8px;
    color: #10C52E;
  }
  