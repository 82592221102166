/* TermsOfServicePopup.css */

.tos-popup {
    position: fixed;
    top: 10%; /* Position slightly below the top */
    left: 50%;
    transform: translate(-50%, 0);
    width: 60%;
    max-width: 800px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1001; /* Ensure it's above PrivacyPolicyPopup */
  }
  
  .popup-contenttos {
    padding: 20px;
    max-height: 70vh; /* Adjust as needed */
    overflow-y: auto;
    
    color: #333;
  }
  
  .popup-content h2 {
    margin-top: 0;
    color: #333;
  }
  
  .popup-content p,
  .popup-content ul,
  .popup-content li {
    color: #333;
  }
  
  .close-button {
    background-color: #FF7F3F;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .close-button:hover {
    background-color: #E5732F;
  }
  