.navbar {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
  }
    .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343a40;
  }
  .navbar-brand img {
    
    margin-right: 10px;
    width: 60px; /* Increase the width of the logo */
    height: auto; /* Maintain the aspect ratio */
}
  

  .navbar-nav .nav-link {
    color: #343a40;
    font-size: 1rem;
    padding-right: 20px;
  }
  
  .navbar-nav .nav-link:hover {
    color: #007bff;
  }
  
  .btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-primary:hover {
    background-color: #007bff;
    color: white;
  }
  
  .btn-primary {
    background-color: #ff7f3f;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #e5732f;
  }
  