/* PrivacyPolicyPopup.css */

.privacy-policy-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Lower than ToS popup */
}

.popup-contentprivacy {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.popup-content h2 {
  margin-top: 0;
  color: #333;
}

.checkbox-container {
  margin-top: 20px;
}

.agree-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.agree-button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

.agree-button:not(:disabled):hover {
  background-color: #45a049;
}
