/* CustomPlanForm.css */
.package-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #64748B;
  }
  
  .btn-custom-plan {
    background-color: #87CEEB; /* Skyblue color */
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    text-align: center;
  }
  
  .btn-custom-plan:hover {
    background-color: #00BFFF;
  }
  
  /* Custom Plan Form Container with Smooth Animation */
  .custom-plan-form-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  
  .custom-plan-form-container.open {
    max-height: 800px; /* Adjust based on the form's content height */
  }
  
  .custom-plan-form {
    margin-top: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .custom-plan-form h3 {
    font-size: 1.5rem;
    color: #334E68;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    font-size: 1rem;
    color: #102A43;
  }
  
  /* Initial Month Fee */
  .initial-month-fee {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #102A43;
    text-align: right; /* Align the initial month fee to the right */
  }
  
  .total-cost {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #102A43;
    text-align: right; /* Align the total cost to the right */
  }
  
  .btn-submit {
    background-color: #87CEEB;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* Space above the submit button */
  }
  
  .btn-submit:hover {
    background-color: #00BFFF;
  }
  