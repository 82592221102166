.refer-container {
    max-width: 850px;
    margin: 60px auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .refer-heading {
    font-size: 2.5rem;
    font-weight: 600;
    color: #1e3d58;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
  }
  
  .refer-subheading {
    font-size: 1.25rem;
    color: #2c3e50;
    margin-bottom: 40px;
    font-family: 'Lato', sans-serif;
  }
  
  .refer-content p {
    font-size: 1.15rem;
    color: #4a4a4a;
    margin-bottom: 40px;
    line-height: 1.7;
  }
  
  .refer-content .highlight {
    color: #3498db;
    font-weight: bold;
  }
  
  .refer-action h2 {
    font-size: 1.75rem;
    color: #1e3d58;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
  }
  
  .refer-list {
    list-style-type: disc;
    padding-left: 40px;
    margin: 0 0 40px;
    text-align: left;
  }
  
  .refer-list li {
    font-size: 1.1rem;
    color: #2c3e50;
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif;
  }
  
  .refer-info {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    font-size: 1.1rem;
    color: #2c3e50;
    font-family: 'Lato', sans-serif;
  }
  
  .refer-info a {
    color: #3498db;
    font-weight: bold;
    text-decoration: none;
  }
  
  .refer-info a:hover {
    text-decoration: underline;
  }
  
  .refer-heading,
  .refer-subheading {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  