/* Footer.css */
footer {
  background-color: #f8f9fa;
  padding: 4rem 0; /* Increase padding to make the footer taller */
  position: relative;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem; /* Adjust font size to make text bigger */
}


.footer h6 {
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
}

.footer p,
.footer a {
  color: #6c757d;
  text-decoration: none;
}

.footer a:hover {
  color: #007bff;
}

.footer i {
  margin-right: 10px;
}

.footer .text-reset {
  color: #6c757d;
}

.footer .text-reset:hover {
  color: #007bff;
}

.footer .me-4 {
  margin-right: 1.5rem;
}

.footer .border-bottom {
  border-bottom: 1px solid #dee2e6;
}

/* Limit the width of the "Get connected" section */
.footer .d-flex {
  max-width: 100%; /* Adjust this percentage to control how much width the "Get connected" section takes */
  margin: 0 auto; /* Center the section */
}

.footer-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  font-size: 0.9rem;
}

.body-content {
  min-height: calc(100vh - 150px); /* Ensure the body content takes up the entire height of the viewport minus footer */
}
